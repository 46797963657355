<template>
  <div>
    <b-tabs nav-wrapper-class="faq-navigation" nav-class="nav-left">
      <b-tab>
        <template #title class="bg-primary">
          <feather-icon icon="MenuIcon" />
          <span>Servis Formu</span>
        </template>
        <service-form></service-form>
      </b-tab>
      <b-tab v-if="datasource.tab_delivery.visible">
        <template #title>
          <feather-icon icon="TruckIcon" />
          <span>Teslimat Süreci</span>
        </template>
        <service-delivery></service-delivery>
      </b-tab>
      <b-tab v-if="datasource.tab_device.visible">
        <template #title>
          <feather-icon icon="MonitorIcon" />
          <span>Cihazlar</span>
        </template>
        <div>
           <service-device></service-device>
        </div>
      </b-tab>
      <b-tab v-if="datasource.tab_information.visible">
        <template #title>
          <feather-icon icon="MonitorIcon" />
          <span>Bilgilendirmeler (QA)</span>
        </template>
        <div>
          <service-customer-information></service-customer-information>
        </div>
      </b-tab>
      <b-tab v-if="datasource.tab_payment.visible">
        <template #title>
          <feather-icon icon="CreditCardIcon" />
          <span>Ödemeler </span>
        </template>
        <div>
         <service-payment></service-payment>
        </div>
      </b-tab>
      <b-tab v-if="datasource.tab_proposal.visible">
        <template #title>
          <feather-icon icon="PocketIcon" />
          <span>Teklif Süreci</span>
        </template>
        <div>
          <service-proposal></service-proposal>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { tr } from "flatpickr/dist/l10n/tr.js";
import utils from "@/service/utils";
import _ from "lodash";
import ServiceDevice from "./ServiceDevice.vue";
import ServiceForm from "./ServiceForm.vue";
import ServiceDelivery from "./ServiceDelivery.vue";
import ServiceDeliveryTask from "./ServiceDeliveryTask.vue";
import ServiceCustomerInformation from "./ServiceCustomerInformation.vue";
import ServicePayment from "./ServicePayment.vue";
import ServiceProposal from "./ServiceProposal.vue";
import { mapState, mapGetters, mapMutations } from "vuex";
export default {
  components: {
    vSelect,
    flatPickr,
    ServiceDevice,
    ServiceForm,
    ServiceDelivery,
    ServiceDeliveryTask,
    ServiceCustomerInformation,
    ServiceProposal,
    ServicePayment
  },
  data() {
    return {
      datasource: {
        tab_delivery: { visible: false },
        tab_device: { visible: false },
        tab_information: { visible: false },
        tab_payment: { visible: false },
        tab_proposal: { visible: false },
      },
    };
  },
  created() {
    this.getServiceDetails();
    let user = utils.getActiveUser();
  },
  methods: {
    userHasView(target)
    { 
      let result = false;
      let user = utils.getActiveUser();

    if( ['general_manager','general_manager_deputy','developer'].includes(user.title_name) > -1 )
      {
        if(target == 'information')
        {
          if( ['1','2'].indexOf(user.access_level) > -1 )
          {
            result = true;
          }
        }

       if(target == 'proposal')
        {
          if( ['1','2'].indexOf(user.access_level) > -1 )
          {
            result = true;
          }
        }

        if(target == 'payment')
        {
          if( ['1'].indexOf(user.access_level) > -1 )
          {
            result = true;
          }
        }
        

      }
      if(user.title_name == 'developer') return true;
      return result;
    },
    getServiceDetails() {
      let sf_uniq = this.$route.query.uniq;
      if (!utils.isNullOrEmpty(sf_uniq)) {
        this.sf.sform.sf_uniq = sf_uniq;
        this.sf.sform.sf_id = 0;
        this.$store.dispatch("getServiceDetails");
      }
    },
    renderSuggestion(suggestion) {
      const data = suggestion.item;
      return (
        <b-list-group>
          <b-list-group-item class="p-1">
            <div class="d-flex align-items-center">
              <div class="d-flex">
                <div>
                  <b-avatar src={data.profile_image} class="mr-50"></b-avatar>
                </div>
                <div>
                  <div>
                    <span>{data.title}</span>
                  </div>
                  <div>
                    <span>{data.gsm_primary}</span>
                    <span class="ml-1">{data.email_primary}</span>
                  </div>
                </div>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      );
    },
  },
  computed: {
    ...mapMutations(["clearServiceDetails"]),
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
    }),
  },
  mounted() {
    this.clearServiceDetails;
  },
  watch: {
    "sf.sform.sf_id": function (n, o) 
    {
      if(n > 0)
      {
        this.datasource.tab_delivery.visible = true;
        this.datasource.tab_device.visible = true
        this.datasource.tab_information.visible = this.userHasView('information');
        this.datasource.tab_proposal.visible = this.userHasView('proposal');
        this.datasource.tab_payment.visible  =  this.userHasView('payment');;
      }
      else
      {
        this.datasource.tab_delivery.visible = false;
        this.datasource.tab_device.visible   = false;
        this.datasource.tab_information.visible = false;
        this.datasource.tab_payment.visible = false;
        this.datasource.tab_proposal.visible = false;
      }

    },
  },
};
</script>
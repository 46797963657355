<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Görev Detayları</h3>
      </div>
      <div class="card-body">
        <b-row>
          <b-col md="6" sm="6">
            <div class="form-group">
              <label class="font-medium text-uppercase">Tür</label>
              <v-select
                :disabled="sf.delivery.d_id > 0 ? true : false"
                v-model="sf.delivery.type"
                :searchable="false"
                :clearable="false"
                :reduce="(curr) => curr.value"
                :options="global.delivery_types"
              />
            </div>
          </b-col>
          <b-col md="6" sm="6">
            <div class="form-group">
              <label class="font-medium text-uppercase"
                >Adreste Olması Gereken Tarih</label
              >
              <flat-pickr
                v-model="sf.task.planned_date"
                class="form-control"
                :config="{
                  enableTime: true,
                  altFormat: 'd.m.Y H:i',
                  altInput: true,
                  dateFormat: 'Y-m-d H:i',
                  locale: 'tr',
                }"
              />
            </div>
          </b-col>
          <b-col lg="6">
            <div class="form-group">
              <label
                class="font-medium text-uppercase badge badge-danger text-white"
                >İlgili Cihaz</label
              >
              <v-select
                :searchable="false"
                :clearable="false"
                v-model="sf.delivery.device_id"
                :reduce="(curr) => curr.device_id"
                :get-option-label="(option) => option.device_name"
                :options="sf.devices"
              />
            </div>
          </b-col>
          <b-col lg="6">
            <div class="form-group">
              <label class="font-medium text-uppercase">İlgili Personel</label>
              <v-select
                :searchable="true"
                :clearable="false"
                v-model="sf.task.staff_id"
                :reduce="(curr) => curr.value"
                :options="global.users"
                label="label"
              />
            </div>
          </b-col>
          <b-col lg="6">
            <div class="form-group">
              <label class="font-medium text-uppercase"
                >Tamamlayan Personel</label
              >
              <v-select
                :searchable="false"
                :clearable="false"
                v-model="sf.task.complated_staff_id"
                :reduce="(curr) => curr.value"
                :options="global.users"
              />
            </div>
          </b-col>
          <b-col md="6" sm="6">
            <div class="form-group">
              <label class="font-medium text-uppercase">Tamamlanan Tarih</label>
              <flat-pickr
                v-model="sf.task.complated_date"
                class="form-control"
                :config="{
                  enableTime: true,
                  altFormat: 'd.m.Y H:i',
                  altInput: true,
                  dateFormat: 'Y-m-d H:i',
                  locale: 'tr',
                }"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6">
            <div class="form-group">
              <label class="font-medium text-uppercase">Görev Tanımı</label>
              <v-select
                v-model="sf.task.task_definition_id"
                :searchable="true"
                :clearable="false"
                :reduce="(curr) => curr.value"
                :options="global.task_todo"
              />
            </div>
          </b-col>
          <b-col lg="6">
            <div class="form-group">
              <label class="font-medium text-uppercase"
                >Aciliyet Derecesi</label
              >
              <v-select
                v-model="sf.task.priority_id"
                :searchable="false"
                :clearable="false"
                :options="global.delivery_priority"
                :reduce="(curr) => curr.value"
              />
            </div>
          </b-col>
        </b-row>
        <div class="form-group">
          <label class="font-medium text-uppercase"
            >Görev Tanım Açıklaması</label
          >
          <textarea
            v-model="sf.task.task_notes"
            class="form-control"
            rows="3"
          ></textarea>
        </div>
        <div v-if="datasource.show_task_cancel_panel">
          <div class="form-group">
            <label class="font-medium text-uppercase text-danger"
              >Görev İptal Nedeni</label
            >
            <textarea
              class="form-control"
              rows="4"
              v-model="sf.task.task_cancel_notes"
            ></textarea>
          </div>
          <div v-if="sf.task.status != 'canceled'" class="form-group">
            <button v-on:click="cancelTaskDelivery()" class="btn btn-danger">
              Görev İptalini Onayla
            </button>
            <button
              v-on:click="datasource.show_task_cancel_panel = false"
              class="btn"
            >
              Vazgeç
            </button>
          </div>
        </div>
        <div class="form-group">
          <label class="font-medium text-uppercase">Açık Adres</label>
          <textarea
            v-model="sf.delivery.delivery_address"
            class="form-control"
            rows="3"
          ></textarea>
        </div>
        <b-row>
          <b-col md="6" lg="6">
            <div class="form-group">
              <label class="font-medium text-uppercase">İl</label>
              <v-select
                :searchable="true"
                :clearable="false"
                :options="global.cities"
                :reduce="(curr) => curr.value"
                v-on:input="changeCity(true)"
                v-model="sf.delivery.city_id"
              />
            </div>
          </b-col>
          <b-col md="6" lg="6">
            <div class="form-group">
              <label class="font-medium text-uppercase">İlçe</label>
              <v-select
                :searchable="true"
                :clearable="false"
                :reduce="(curr) => curr.district_id"
                :options="datasource.districts"
                v-model="sf.delivery.district_id"
              />
            </div>
          </b-col>
          <b-col md="12" lg="12">
            <div class="form-group">
              <label class="font-medium text-uppercase">Konum</label>
              <b-input-group>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Harita konumu"
                  v-model="sf.delivery.location"
                />
                <b-input-group-append>
                  <b-button variant="outline-secondary"> Aç </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
          <b-col v-if="false" md="6" lg="6">
            <div class="form-group">
              <label class="font-medium text-uppercase">Teslimat Ücreti Oluştur</label>
              <b-input-group>
                <b-form-input
                  type="number"
                  step="0.01"
                  autocomplate="off"
                  v-model="sf.delivery.fee_delivery"
                  :readonly="sf.delivery.payment_delivery != null"
                ></b-form-input>
                <template #append>
                  <b-dropdown
                    variant="outline-secondary"
                    :text="_fee_delivery_currency_name"
                  >
                    <b-dropdown-item
                      @click="sf.delivery.fee_delivery_currency_id = item.definition_id"
                      v-for="item in global.currencies"
                      :key="item.definition_id"
                      >{{ item.label }}</b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-input-group>
              <div class="small">Teslimat ücreti oluşturulduktan sonraki aşamalar "Ödemeler" kısmından takip edilecektir.</div>
            </div>
          </b-col>
          <b-col v-if="false" md="6" lg="6">
            <div class="form-group">
              <label class="font-medium text-uppercase">Servis Ücreti Oluştur</label>
              <b-input-group>
                <b-form-input
                  type="number"
                  step="0.01"
                  autocomplate="off"
                  v-model="sf.delivery.fee_service"
                  :readonly="sf.delivery.payment_service != null"
                ></b-form-input>
                <template #append>
                  <b-dropdown
                    :text="
                      getDefinitionName(sf.delivery.fee_service_currency_id)
                    "
                    variant="outline-secondary"
                  >
                    <b-dropdown-item
                      v-on:click="
                        sf.delivery.fee_service_currency_id = item.definition_id
                      "
                      v-for="item in global.currencies"
                      :key="item.definition_id"
                      >{{ item.label }}</b-dropdown-item
                    >
                  </b-dropdown>
                </template>
              </b-input-group>
               <div class="small">Servis ücreti oluşturulduktan sonraki aşamalar "Ödemeler" kısmından takip edilecektir.</div>
            </div>
          </b-col>
        </b-row>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label class="font-medium text-uppercase">Görev Durumu</label>
              <v-select
                :searchable="true"
                :clearable="false"
                :options="global.task_status"
                :reduce="(curr) => curr.value"
                v-model="sf.task.status"
              />
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-between">
          <div
            class="form-group d-flex"
            v-if="
              datasource.show_task_cancel_panel == false &&
              sf.task.status != 'canceled'
            "
          >
            <button v-on:click="saveDeliveryTask()" class="btn btn-primary">
              Kaydet
            </button>
            <button
              v-if="false"
              class="btn btn-secondary ml-1"
              @click="showModalNotification('delivery')"
            >
              Bildirim Gönder
            </button>
          </div>
          <button
            v-if="
              sf.task.task_id > 0 &&
              ['complated', 'canceled'].indexOf(sf.task.status) > -1
            "
            v-on:click="datasource.show_task_cancel_panel = true"
            class="btn text-danger"
          >
            İptal Et
          </button>
        </div>

        <div class="form-group">
          <div class="text-muted" v-if="sf.task.d_id == 0">
            * Görev ataması yapıldıktan sonra kayıt değiştirilmez.
          </div>
        </div>
      </div>
    </div>
    <send-sms-notification></send-sms-notification>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { VueAutosuggest } from "vue-autosuggest";
import SendSmsNotification from "@/views/common/SendSmsNotification.vue";
import ServiceDeliveryTask from "./ServiceDeliveryTask.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { tr } from "flatpickr/dist/l10n/tr.js";
import utils from "@/service/utils";
import _ from "lodash";
export default {
  components: {
    //ServiceDeliveryFormSend,
    //ServiceDeliveryFormTake,
    ServiceDeliveryTask,
    VueAutosuggest,
    vSelect,
    flatPickr,
    SendSmsNotification,
  },
  data() {
    return {
      datasource: {
        show_task_cancel_panel: false,
        districts: [],
        table: {
          selected: null,
          items: [],
          fields: [
            { key: "task_definition", label: "Görev", sortable: false },
            { key: "date", label: "Tarih", sortable: false },
            { key: "staff", label: "Personel", sortable: false },
            { key: "priority", label: "Aciliyet", sortable: false },
            { key: "status", label: "Durum", sortable: false },
          ],
        },
      },
    };
  },
  created() {
    this.changeCity(true);
    this.global.modal_send_sms_notification.customer = this.sf.customer;
  },
  methods: {
    ...mapActions(["saveDeliveryTask", "cancelTaskDelivery"]),
    moment(v) {
      return utils.moment(v);
    },
    setDeliveryTask(item) {
      if (item.length <= 0) return;
      let je = { ...item[0].item };
      this.sf.task = je;
    },
    getDefinitionName(id) {
      return utils.getDefinitionName(id);
    },
    changeCity(setEmpty = false) {
      if (setEmpty) {
        this.sf.delivery.district_id = "0";
        this.datasource.districts = [];
      }
      this.getDistricts();
    },
    getDistricts() {
      let city_id = this.sf.delivery.city_id;
      let items = utils.getDistricts(city_id, { viewtype: "vselect" });
      this.datasource.districts = items;
    },
    showModalNotification(entity) {
      this.global.modal_send_sms_notification.entity_type = entity;
      if (entity == "delivery") {
        this.global.modal_send_sms_notification.mode = "select";
      }
      this.$root.$emit("bv::toggle::modal", "modal_send_notification_sms");
    },
    clearDeliverySMS() {
      this.sf.delivery.notification_sms_text = "";
      this.sf.delivery.notification_sms_definition_id = null;
    },
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
      ...mapGetters(["getDeliveryTasks"]),
    }),
    table_tasks() {
      let data = this.getDeliveryTasks;
      data = Object.values(data);
      let result = [];
      if (data != null) {
        data.forEach((q) => {
          let item = {
            item: q,
            task_definition: q.definition_name,
            date: {
              planned_date: q.planned_date,
              complated_date: q.complated_date,
            },
            staff: {
              staff: q.staff != null ? q.staff.user_namesurname : "",
              complated_staff:
                q.complated_staff != null
                  ? q.complated_staff.user_namesurname
                  : "",
            },
            status: {
              status: q.status,
              status_text: q.status_text,
            },
            priority: q.priority,
          };
          result.push(item);
        });
      }
      return result;
    },
    showTabDeliveryTask() {
      return true;
    },
    _fee_delivery_currency_name()
    {
      let result = '';
      let d = this.getDefinitionName(this.sf.delivery.fee_delivery_currency_id);
      console.log('his.sf.delivery.fee_delivery_curreny_id',this.sf.delivery.fee_delivery_currency_id);
       console.log('d',d);
      if(d!=null) result = d;
      return result
    }
  },
  watch: {
    "sf.task.task_id": function (eV) {
      this.datasource.show_task_cancel_panel =
        this.sf.task.status == "canceled" ? true : false;
    },
  },
};
</script>

<style>
</style>
<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="card-title">Teslimat Görevleri</div>
          <button v-on:click="newDelivery()" class="btn btn-sm btn-primary">
            Yeni Görev
          </button>
      </div>
      <div class="card-body p-0">
        <b-table
          ref="table_sf_delivery"
          responsive
          striped
          small
          hover
          selectable
          select-mode="single"
          :items="list_delivery"
          :fields="datasource.table.fields"
          @row-selected="setTask"
          v-model="datasource.table.selected"
          :tbody-tr-class="rowClass"
          class="mb-0 align-top"
        >
        <template #cell(d_id)="data">
          {{ data.item.item.d_id }}
        </template>
          <template #cell(task_name)="data">
            <div class="font-weight-bold">
              {{ data.value }}
            </div>
          </template>
          <template #cell(staff_item)="data">
            <div v-if="data.value.staff != null">
              {{ data.value.staff }}
            </div>
            <div v-if="data.value.staff_complated != null">
              <span>Tamamlayan</span>
              <span class="ml-1">{{ data.value.staff_complated }}</span>
            </div>
          </template>
          <template #cell(date_item)="data">
            <div>
              <div class="font-medium">Planlanan Tarih:</div>
              <div>
                <span v-if="data.value.planned_date !=null">
                  {{ data.value.planned_date }}
                </span>
              </div>
            </div>
            <div class="mt-05rem" v-if="data.value.complated_date != null">
              <div class="font-medium">Tamamlanma Tarihi:</div>
              <div>
                <span v-if="data.value.complated_date != ''">
                  {{ data.value.complated_date }}
                </span>
                <span v-else>-</span>
              </div>
            </div>
          </template>
          <template #cell(sf_date)="data">
            <div v-if="data.value != null">
              <div>
                {{ moment(data.item.sf_date).format("DD.MM.YYYY") }}
              </div>
            </div>
          </template>
        <template #cell(status)="data">
          <status-badge :status="data.value" />
        </template>
        </b-table>
      </div>
      <div class="card-body pt-0" v-if="list_delivery.length == 0">
        <div class="text-muted text-center p-4">
          Bu servis için herhangi bir teslimat süreç kayıtı bulunmamaktadır.
          Yeni Süreç Başlat seçeneğini kullanarak yeni bir gönderim/alım süreci
          başlatabilirsiniz.
        </div>
      </div>
    </div>
    <service-delivery-form
      v-if="datasource.showdeliveryform"
    ></service-delivery-form>
  </div>
</template>

<script>
import StatusBadge from "@/views/common/StatusBadge.vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import { tr } from "flatpickr/dist/l10n/tr.js";
import utils from "@/service/utils";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import ServiceDeliveryForm from "./ServiceDeliveryForm.vue";
export default {
  components: {
    vSelect,
    flatPickr,
    ServiceDeliveryForm,
    StatusBadge,
  },
  data() {
    return {
      datasource: {
        showdeliveryform: false,
        delivery_list: [],
        table: {
          fields: [
          {
              key: "d_id",
              label: "#",
              sortable: false,
              selected: true,
            },
             {
              key: "type",
              label: "Tür",
              sortable: false,
              selected: true,
            },
            {
              key: "task_name",
              label: "Görev",
              sortable: false,
              selected: true,
            },
            {
              key: "staff_item",
              label: "Personel",
              sortable: false,
              selected: true,
            },
            {
              key: "date_item",
              label: "Tarih",
              sortable: false,
              tdClass: "minw14rem pr-0 pl-1",
              thClass: "minw14rem pr-0 pl-1",
            },
            { key: "status", label: "Durum", sortable: false },
          ],
          selected: null,
        },
      },
    };
  },
  computed: {
    list_delivery() {
      let result = [];
      let items = this.sf.delivery_list;
      items.forEach((q, index) => 
      {
 
        let new_delivery = null;
        let new_task = null;

        if (q.task != null) 
        {
          new_task = { ...q.task };
        }

        new_delivery = { ...q };
        delete new_delivery.task;

        let date_item = { planned_date: null, complated_date: null };
        let staff_item = { staff: null, staff_complated: null };

        let task_name = null;
        if (q.task != null) {
          task_name = q.task.definition_name;
          staff_item.staff = utils.getUser(q.task.staff_id, "user_namesurname");
          staff_item.staff_complated = utils.getUser(
            q.task.complated_staff_id,
            "user_namesurname"
          );

          date_item.planned_date = utils
            .moment(q.task.planned_date)
            .format("DD.MM.YYYY HH:mm");

          if (q.task.complated_date != null) {
            date_item.complated_date = utils
              .moment(q.task.complated_date)
              .format("DD.MM.YYYY HH:mm");
          }
        }
 
        let status_item = { status:null,status_text:null };

        if(q.task!=null) {
          status_item.status = q.task.status;
          status_item.status_text = q.task.status_text;
        }
        let item = 
        {
          type:utils.getDeliveryTypes(q.type).label,
          task_name: task_name,
          date_item: date_item,
          staff_item: staff_item,
          status:status_item,
          item: q,
          item_task: new_task,
          item_delivery: new_delivery,
        };
       console.log('qqq',q)
        result.push(item);
      });

      return result;
    },
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
    }),
  },
  methods: {
    moment(data) {
      return utils.moment(data);
    },
    setTask(item) {
      if (item.length > 0) 
      {
         
       let newitem       = { ...item[0] };
        let district_id  = newitem.item_delivery.district_id;
        this.sf.task     = newitem.item_task;
        this.sf.delivery = newitem.item_delivery;

        setTimeout(()=> 
        {
          this.sf.delivery.district_id = district_id;
        })
        
        if(Object.keys(this.sf.delivery).length == 0)
        {
           this.$store.commit("clearDelivery");
        }
        this.datasource.showdeliveryform = true;
      }
    },
    newDelivery() {
      this.datasource.table.selected = [];
      this.datasource.showdeliveryform = true;
      this.$store.commit("clearDelivery");
      this.$store.commit("clearTask");
    },
    rowClass(item, type) {
      if (!item || type !== "row") {
        return;
      }
      if (item.item.d_id === this.sf.delivery.d_id) {
        return "b-table-row-selected table-active";
      }
    },
  },
};
</script>

<style>
</style>
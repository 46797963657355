<template>
    <div>
        <div class="card" v-if="crudmode == 'update'">
            <div class="card-header pl-0 pr-0">
                <h3 class="card-titles">Görev Detayları</h3>
                <button v-on:click="newTask()" class="btn btn-sm btn-primary">
                    Yeni Görev
                </button>
            </div>
            <div class="card-body pl-0 pr-0">
                <div class="form-group">
                    <label class="font-medium text-uppercase mb-0 text-danger">Görev</label>
                    <div class="font-weight-bold">
                        {{ sf.sdelivery_task.definition_name }}
                    </div>
                </div>
                <b-row>
                    <b-col>
                        <div class="form-group" v-if="sf.sdelivery_task.staff != null">
                            <label class="font-medium text-uppercases mb-0">Atanan Personel</label>
                            <div>{{ sf.sdelivery_task.staff.user_namesurname }}</div>
                        </div>
                        <div class="form-group">
                            <label class="font-medium text-uppercases mb-0">Görev Durumu</label>
                            <div class="text-primary">
                                {{ sf.sdelivery_task.status_text }}
                            </div>
                        </div>
                        <div class="form-group" v-if="sf.sdelivery_task.status == 'canceled'">
                            <label class="font-medium text-uppercases mb-0">İptal Nedeni</label>
                            <div class="">
                                {{ sf.sdelivery_task.task_cancel_notes }}
                            </div>
                        </div>
                        <div v-if="sf.sdelivery_task.complated_date != null">
                            <div class="form-group">
                                <label class="font-medium text-uppercases mb-0">Görevi Tamamlayan Personel</label>
                                <div class="">
                                    <span class="tex">
                                        {{ sf.sdelivery_task.complated_staff.user_namesurname }}
                                    </span>
                                    <span class="ml-1">
                                        Tamamlanma T: {{ sf.sdelivery_task.complated_staff.complated_date }}
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="font-medium text-uppercases mb-0">Tamamlanma Süresi</label>
                                <div class="text-primary">
                                    {{ sf.sdelivery_task.status_text }}
                                </div>
                            </div>
                        </div>
                    </b-col>
                    <b-col>
                        <div class="form-group" v-if="sf.sdelivery_task.created_props != null">
                            <label class="font-medium text-uppercases mb-0">Kayıt</label>
                            <div>
                                {{ sf.sdelivery_task.created_props.created_by_name }}
                                <span class="ml-1">{{ moment(sf.sdelivery_task.created_props.created_at).format( "DD.MM.YYYY HH:mm" ) }}</span>
                            </div>
                        </div>
                        <div class="form-group" v-if="sf.sdelivery_task.updated_props != null">
                            <label class="font-medium text-uppercases mb-0">Güncellenme</label>
                            <div>
                                {{ sf.sdelivery_task.updated_props.updated_by_name }}
                                <span class="ml-1">{{ moment(sf.sdelivery_task.updated_props.updated_at).format( "DD.MM.YYYY HH:mm" ) }}</span>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>

        <b-row>
            <b-col md="6" lg="6">
                <div class="form-group">
                    <label class="font-medium text-uppercase">Planlanan Görev Tarihi</label>
                    <flat-pickr
                        class="form-control"
                        v-model="sf.sdelivery_task.planned_date"
                        :config="{
              enableTime: true,
              altFormat: 'd.m.Y H:i',
              altInput: true,
              dateFormat: 'Y-m-d H:i',
              locale: 'tr',
            }"
                    />
                </div>
            </b-col>
            <b-col md="6" lg="6">
                <div class="form-group">
                    <label class="font-medium text-uppercase">Öncelik Derecesi</label>
                    <v-select v-model="sf.sdelivery_task.priority_id" :searchable="false" :clearable="true" :options="global.delivery_priority" :reduce="(curr) => curr.value" />
                </div>
            </b-col>
        </b-row>
        <div class="form-group" v-if="crudmode == 'create'">
            <label class="font-medium text-uppercase">İlgili Personel</label>
            <v-select :searchable="false" :clearable="true" v-model="sf.sdelivery_task.staff_id" :reduce="(curr) => curr.value" :options="global.users" />
        </div>
        <div class="form-group">
            <label class="font-medium text-uppercase">Görev Tanımı</label>
            <v-select v-model="sf.sdelivery_task.task_definition_id" :searchable="true" :clearable="false" :reduce="(curr) => curr.value" :options="global.task_todo" />
        </div>
        <div class="form-group">
            <label class="font-medium text-uppercase">Tanım Açıklaması</label>
            <textarea v-model="sf.sdelivery_task.task_notes" class="form-control" rows="3"></textarea>
        </div>
        <div v-if=" show_task_cancel_panel &&
        sf.sdelivery_task.status != 'canceled' && sf.sdelivery_task.task_id > 0
      "
        >
            <div class="form-group">
                <label class="font-medium text-uppercase">Görev İptal Nedeni</label>
                <textarea class="form-control" rows="4" v-model="sf.sdelivery_task.task_cancel_notes"></textarea>
            </div>
            <div class="form-group">
                <button v-on:click="cancelDeliveryTask()" class="btn btn-danger">
                    Görev İptalini Onayla
                </button>
                <button v-on:click="show_task_cancel_panel = false" class="btn">
                    Vazgeç
                </button>
            </div>
        </div>
        <div
            class="form-group"
            v-if="
        show_task_cancel_panel == false &&
        sf.sdelivery_task.status != 'canceled'
      "
        >
            <button v-on:click="saveDelivery()" class="btn btn-primary">
                Kaydet
            </button>
            <button v-if="sf.sdelivery_task.task_id > 0" v-on:click="show_task_cancel_panel = true" class="btn text-danger ml-1">
                İptal Et
            </button>
        </div>
        <div class="form-group">
            <div class="text-muted" v-if="sf.sdelivery_task.d_id == 0">
                * Görev ataması yapıldıktan sonra kayıt değiştirilmez.
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
    import { VueAutosuggest } from "vue-autosuggest";
    import vSelect from "vue-select";
    import flatPickr from "vue-flatpickr-component";
    import _ from "lodash";
    import utils from "@/service/utils";

    export default {
        components: {
            VueAutosuggest,
            vSelect,
            flatPickr,
        },
        data() {
            return {
                show_task_cancel_panel: false,
            };
        },
        methods: {
            moment(data) {
                return utils.moment(data);
            },
            saveDelivery() {
                try {
                    if (this.sf.sdelivery_task.task_id <= 0) {
                        this.addDeliveryTask();
                    } else {
                        this.updateTaskDelivery();
                    }
                } catch (e) {
                    console.log("error", e);
                }
            },
            cancelDeliveryTask() {
                this.cancelTaskDelivery();
            },

            newTask() {
                this.clearTask();
                this.show_task_cancel_panel = false;
            },
            ...mapActions(["addDeliveryTask", "updateTaskDelivery", "cancelTaskDelivery"]),
            ...mapMutations(["clearTask"]),
        },
        computed: {
            crudmode() {
                return this.sf.sdelivery_task.task_id == 0 ? "create" : "update";
            },
            ...mapState({
                sf: (state) => state.sf,
                global: (state) => state.global,
            }),
        },
    };
</script>

<style></style>

<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="card-title">Teklifler</div>
        <div>
          <button
            v-on:click="newProposalEntity()"
            class="btn btn-sm btn-primary">
            Yeni Teklif
          </button>
        </div>
      </div>
      <div class="card-body p-0">
        <b-table
          ref="selectableproposallist"
          responsive
          striped
          small
          hover
          selectable
          select-mode="single"
          :items="list_proposal"
          :fields="datasource.table.fields"
          @row-selected="setProposal"
          v-model="datasource.table.selected"
          :tbody-tr-class="rowClass"
          class="mb-0 align-top">
          <template #cell(proposal_date)="data">
            <div>{{ moment(data.value).format("DD.MM.YYYY") }}</div>
          </template>
          <template #cell(status)="data">
            <div>{{ data.value.status_text }}</div>
          </template>
          <template #cell(date_props)="data">
            <div>
              {{
                moment(data.value.created_props.created_at).format("DD.MM.YYYY HH:mm")
              }}
            </div>
            <div>{{ data.value.created_props.created_by_name }}</div>
          </template>
        </b-table>
      </div>
      <div class="card-body pt-0" v-if="list_proposal.length == 0">
        <div class="text-muted text-center p-4">
          Bu servis için herhangi bir teslimat süreç kayıtı bulunmamaktadır.
          Yeni Süreç Başlat seçeneğini kullanarak yeni bir gönderim/alım süreci
          başlatabilirsiniz.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Logo from "@core/layouts/components/Logo.vue";
import Cleave from "vue-cleave-component";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { tr } from "flatpickr/dist/l10n/tr.js";
import utils from "@/service/utils";
import _ from "lodash";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    vSelect,
    flatPickr,
    Logo,
    Cleave,
  },
  props: ["showproposalpanel"],
  created() {},
  data() {
    return {
      datasource: {
        table: {
          selected: null,
          items: [],
          fields: [
            { key: "proposal_date", label: "Teklif Tarihi", sortable: false },
            { key: "status", label: "Durum", sortable: false },
            { key: "is_active", label: "Aktif Mi", sortable: false },
            { key: "date_props", label: "Oluşturulma", sortable: false },
          ],
        },
      },
    };
  },
  methods: {
    ...mapMutations(["newProposal"]),
    newProposalEntity() {
      this.newProposal();
      this.sf.proposal.proposal_no =
        utils.moment().format("DMYY") +
        "" +
        utils.generateUniq({ size: 2, number: true });
    },
    moment(v) {
      return utils.moment(v);
    },
    setProposal(item) 
    {
      if(item.length == 0) return;
      this.sf.proposal = Object.assign({},item[0].item);
      let items = item[0].item.items;
      items.forEach((q) => 
      {
        q.quantity = parseInt(q.quantity);
      });
    },
    rowClass(item, type) {
      if (!item || type !== "row") {
        return;
      }
    },
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
    }),
    list_proposal() {
      let result = [];
      this.sf.proposals.forEach((q) => {
        let status = utils.getDefinition(q.status_id);
        let item = {
          proposal_date: q.proposal_date,
          status: { status_text: status.name },
          is_active: q.is_active == '1' ? "Evet" : "Hayır",
          date_props: {
            created_props: q.created_props,
            updated_props: q.updated_props,
          },
          item: q,
        };
        result.push(item);
      });
      return result;
    },
  },
};
</script>

<template>
  <div>
    <service-proposal-list></service-proposal-list>
    <section class="invoice-add-wrapper">
      <b-row class="invoice-add">
        <b-col cols="12" xl="9" md="8">
          <b-form @submit.prevent>
            <b-card no-body class="invoice-preview-card">
              <b-card-body class="invoice-padding pb-0">
                <div class="row mb-1 mt-1">
                  <div class="col-sm-5">
                    <div class="logo-wrapper">
                      <Logo width="230" class="img-responsive img-fluid mb-2" />
                    </div>
                    <b-card-text class="mb-25">
                      {{ officeAddress }}
                    </b-card-text>
                  </div>
                  <div class="col-sm-7 mt-2">
                    <div class="d-flex flex-column float-right">
                      <b-row>
                        <b-col> <h5 class="invoice-title">Teklif No</h5></b-col>
                        <b-col class="col-sm-auto">
                          <span class="ml-1"
                            >#{{ sf.proposal.proposal_no }}</span
                          ></b-col
                        >
                      </b-row>
                      <b-row>
                        <b-col>
                          <h5 class="invoice-title">Teklif Tarihi</h5></b-col
                        >
                        <b-col class="col-sm-auto">
                          <span
                            v-if="
                              sf.proposal.proposal_date != null &&
                              sf.proposal.proposal_date != ''
                            "
                            class="ml-1 text-danger"
                            >{{
                              moment(sf.proposal.proposal_date).format(
                                "DD.MM.YYYY"
                              )
                            }}</span
                          >
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </b-card-body>
              <hr class="invoice-spacing" />
              <!-- Invoice Client & Payment Details -->
              <b-card-body class="invoice-padding pt-0">
                <b-row class="invoice-spacing">
                  <!-- Col: Invoice To -->
                  <b-col cols="12" xl="6" class="mb-lg-1">
                    <h6 class="mb-2">Müşteri Bilgileri</h6>

                    <!-- Selected Client -->
                    <div class="mt-1">
                      <h6 class="mb-25">
                        {{ customerInfo.title }}
                      </h6>
                      <b-card-text class="mb-25">
                        {{ customerInfo.address }}
                      </b-card-text>
                    </div>
                  </b-col>

                  <!-- Col: Payment Details -->
                  <b-col
                    xl="6"
                    cols="12"
                    class="mt-xl-0 mt-2 justify-content-end d-xl-flex d-block"
                  >
                    <div>
                      <h6 class="mb-2">Ödeme Detayı</h6>
                      <table>
                        <tbody>
                          <tr v-if="sf.proposal.show_total_price">
                            <td class="pr-1">Toplam Tutar:</td>
                            <td>
                              <span class="font-weight-bold">
                                {{ sf.proposal.grand_total }}
                                {{ sf.proposal.currency_type }}
                              </span>
                            </td>
                          </tr>
                          <tr v-if="sf.proposal.bank_id > 0">
                            <td class="pr-1">IBAN:</td>
                            <td>{{ getBank.attr_1 }}</td>
                          </tr>
                          <tr v-if="sf.proposal.bank_id > 0">
                            <td class="pr-1">Hesap Adı:</td>
                            <td>{{ getBank.attr_2 }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
              <b-card-body class="invoice-padding form-item-section">
                <div class="repeater-form">
                  <b-row
                    v-for="(item, index) in getProposalItems"
                    :key="index"
                    ref="row"
                    class="pb-2"
                  >
                    <b-col cols="12" class="border rounded p-2">
                      <div class="form-group">
                        <label class="text-uppercase font-medium">Açıklamalar</label
                        >
                        <textarea
                          v-model="item.notes"
                          class="form-control"
                        ></textarea>
                      </div>
                      <b-row>
                        <b-col cols="2">
                          <div class="form-group">
                            <label class="text-uppercase font-medium d-block"
                              >Adet</label
                            >
                            <b-form-spinbutton
                              @change="getRowTotal(item)"
                              v-model="item.quantity"
                              inline
                            ></b-form-spinbutton>
                          </div>
                        </b-col>
                        <b-col>
                          <div class="form-group">
                            <label class="text-uppercase font-medium"
                              >Birim Fiyat</label
                            >
                            <cleave
                              :onValueChanged="getRowTotal(item)"
                              v-model="item.price_unit"
                              class="form-control"
                              :raw="false"
                              :options="{
                                numeral: true,
                                numeralThousandsGroupStyle: 'thousand',
                                numeralIntegerScale: 6,
                              }"
                              placeholder="0,00"
                            />
                          </div>
                        </b-col>
                        <b-col>
                          <div class="form-group">
                            <label class="text-uppercase font-medium"
                              >Toplam</label
                            >
                            <cleave
                              readonly
                              :value="item.price_total"
                              class="form-control"
                              :raw="false"
                              :options="{
                                numeral: true,
                                numeralThousandsGroupStyle: 'thousand',
                                numeralIntegerScale: 6,
                              }"
                              placeholder="0,00"
                            />
                          </div>
                        </b-col>
                        <b-col cols="12">
                          <button
                            v-on:click="removeItem(item, index)"
                            class="btn btn-outline-danger btn-sm"
                          >
                            Sil
                          </button>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
                <b-button
                  size="sm"
                  variant="primary"
                  @click="addNewItemProposal"
                >
                  Yeni Satır
                </b-button>
                <div class="mt-2" v-if="sf.proposals.length > 0">
                  <b-row class="mb-1 ml-n2 mr-n2 border-bottom">
                    <b-col class="text-uppercase font-weight-bold"
                      >Teklif Toplam</b-col
                    >
                    <b-col
                      >{{ sf.proposal.proposal_total }}
                      {{ sf.proposal.currency_type }}</b-col
                    >
                  </b-row>
                  <b-row class="mb-1 ml-n2 mr-n2 border-bottom">
                    <b-col class="text-uppercase font-weight-bold"
                      >İskonto Tutarı</b-col
                    >
                    <b-col
                      >{{ sf.proposal.discount_total }}
                      {{ sf.proposal.currency_type }}</b-col
                    >
                  </b-row>
                  <b-row class="mb-1 ml-n2 mr-n2 border-bottom">
                    <b-col class="text-uppercase font-weight-bold"
                      >Ara Toplam
                    </b-col>
                    <b-col
                      >{{ sf.proposal.sub_total }}
                      {{ sf.proposal.currency_type }}</b-col
                    >
                  </b-row>
                  <b-row class="mb-1 ml-n2 mr-n2 border-bottom">
                    <b-col class="text-uppercase font-weight-bold"
                      >Toplam KDV</b-col
                    >
                    <b-col
                      >{{ sf.proposal.kdv_total }}
                      {{ sf.proposal.currency_type }}</b-col
                    >
                  </b-row>
                  <b-row class="mb-1 ml-n2 mr-n2 border-bottom">
                    <b-col class="text-uppercase font-weight-bold"
                      >Genel Toplam</b-col
                    >
                    <b-col
                      ><span class="text-primary font-weight-bold"
                        >{{ sf.proposal.grand_total }}
                        {{ sf.proposal.currency_type }}</span
                      ></b-col
                    >
                  </b-row>
                </div>
              </b-card-body>
              <hr class="invoice-spacing" />
              <b-card-body class="invoice-padding pt-0">
                <span class="font-weight-bold">Teklif İmza: </span>
                <b-form-textarea v-model="sf.proposal.proposal_footer_notes" />
              </b-card-body>
            </b-card>
          </b-form>
        </b-col>

        <b-col cols="12" md="4" xl="3" class="invoice-actions">
          <div>
            <div class="row">
              <div class="col-sm-12 col-lg-12">
                <b-form-group
                  label-class="text-uppercase font-weight-bold mb-0"
                  label="Teklif Tarihi"
                >
                  <flat-pickr
                    v-model="sf.proposal.proposal_date"
                    class="form-control"
                    :config="{
                      disabled: true,
                      enableTime: false,
                      altFormat: 'd.m.Y',
                      altInput: true,
                      dateFormat: 'Y-m-d',
                      locale: 'tr',
                    }"
                  />
                </b-form-group>
              </div>
              <div class="col-sm-12">
                <b-form-group
                  label-class="text-uppercase font-weight-bold mb-0"
                  label="Geçerlilik Süresi"
                >
                  <flat-pickr
                    class="form-control"
                    v-model="sf.proposal.validty_date"
                    :config="{
                      disabled: true,
                      enableTime: false,
                      altFormat: 'd.m.Y',
                      altInput: true,
                      dateFormat: 'Y-m-d',
                      locale: 'tr',
                    }"
                  />
                </b-form-group>
              </div>
            </div>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label-class="text-uppercase font-weight-bold mb-0"
                  label="İlgili Banka"
                  label-for="payment-method"
                >
                  <v-select
                    v-model="sf.proposal.bank_id"
                    :options="global.banks"
                    :searchable="true"
                    :clearable="false"
                    :reduce="(curr) => curr.value"
                  >
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label-class="text-uppercase font-weight-bold mb-0"
                  label="Personel"
                  label-for="payment-method"
                >
                  <v-select
                    v-model="sf.proposal.user_id"
                    :searchable="false"
                    :clearable="false"
                    :options="global.users"
                    :reduce="(curr) => curr.value"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label-class="text-uppercase font-weight-bold mb-0"
                  label="Teklif Durumu"
                  label-for="payment-method"
                >
                  <v-select
                    v-model="sf.proposal.status_id"
                    :searchable="false"
                    :clearable="false"
                    :reduce="(curr) => curr.value"
                    :options="global.proposal_status"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label-class="text-uppercase font-weight-bold mb-0"
                  label="Aktif Mi"
                  label-for="payment-method"
                >
                  <v-select
                    v-model="sf.proposal.is_active"
                    :searchable="false"
                    :clearable="false"
                    :options="global.yesno"
                    :reduce="(curr) => curr.value"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label-class="text-uppercase font-weight-bold mb-0"
                  label="KDV"
                  label-for="payment-method"
                >
                  <v-select
                    v-model="sf.proposal.kdv"
                    :searchable="false"
                    :clearable="false"
                    :options="global.kdv"
                    :reduce="(curr) => curr.value"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                {{ sf.proposal.currency_type_id }}
                <b-form-group
                  label-class="text-uppercase font-weight-bold mb-0"
                  label="Döviz Cinsi"
                  label-for="payment-method"
                >
                  <v-select
                    v-model="sf.proposal.currency_type"
                    :searchable="false"
                    :clearable="true"
                    :options="global.currency_type"
                    :reduce="(curr) => curr.value"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label-class="text-uppercase font-weight-bold mb-0"
                  label="İskonto"
                >
                  <b-input-group>
                    <cleave
                      :onValueChanged="calculateProposal()"
                      v-model="sf.proposal.discount"
                      id="proposaldiscount"
                      class="form-control"
                      :raw="false"
                      :options="{
                        numeral: true,
                        numeralThousandsGroupStyle: 'thousand',
                      }"
                      placeholder="10,000"
                    />
                    <b-input-group-append>
                      <b-button
                        v-on:click="
                          sf.proposal.discount_type = 'percent';
                          calculateProposal();
                        "
                        :variant="
                          sf.proposal.discount_type == 'percent'
                            ? 'primary'
                            : 'outline-secondary'
                        "
                        >%</b-button
                      >
                      <b-button
                        v-on:click="
                          sf.proposal.discount_type = 'price';
                          calculateProposal();
                        "
                        :variant="
                          sf.proposal.discount_type == 'price'
                            ? 'primary'
                            : 'outline-secondary'
                        "
                        >Birim</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group
              label-class="text-uppercase font-weight-bold mb-0"
              label="Özel Notlar"
            >
              <textarea
                v-model="sf.proposal.private_notes"
                class="form-control"
              ></textarea>
            </b-form-group>
            <div
              class="
                d-flex
                justify-content-between
                align-items-center
                form-group
                mt-2
              "
            >
              <label class="mb-0" for="cb_show_total_price"
                >Toplam Tutarı Göster</label
              >
              <b-form-checkbox
                id="cb_show_total_price"
                v-model="sf.proposal.show_total_price"
                switch
              />
            </div>
            <div
              class="
                d-flex
                justify-content-between
                align-items-center
                form-group
              "
            >
              <label class="mb-0" for="cb_show_kdv">KDV Göster</label>
              <b-form-checkbox
                id="cb_show_kdv"
                v-model="sf.proposal.show_kdv"
                switch
              />
            </div>
            <div
              class="
                d-flex
                justify-content-between
                align-items-center
                form-group
              "
            >
              <label class="mb-0" for="cb_show_discount"
                >İskonto Tutarını Göster</label
              >
              <b-form-checkbox
                id="cb_show_discount"
                v-model="sf.proposal.show_discount"
                switch
              />
            </div>
            <div
              class="
                d-flex
                justify-content-between
                align-items-center
                form-group
              "
            >
              <label class="mb-0" for="show_grand_total"
                >Genel Toplamı Göster</label
              >
              <b-form-checkbox
                id="cb_show_grand_total"
                v-model="sf.proposal.show_grand_total"
                switch
              />
            </div>
            <div class="form-group mt-4">
              <button
                v-on:click="saveProposalEntity()"
                class="btn btn-block btn-success"
              >
                Kaydet 
              </button>
            </div>
            <b-button-group
              v-if="sf.proposal.proposal_id > 0"
              class="d-flex justify-content-between flex-wrsap"
            >
              <b-button
                v-on:click="download()"
                class="w-100"
                variant="outline-secondary"
              >
                İndir</b-button
              >

              <b-button class="w-100" variant="outline-secondary"
                >İptal</b-button
              >
            </b-button-group>
          </div>
        </b-col>
      </b-row>
    </section>
  </div>
</template>
<script>
import Logo from "@core/layouts/components/Logo.vue";
import Cleave from "vue-cleave-component";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { tr } from "flatpickr/dist/l10n/tr.js";
import utils from "@/service/utils";
import _ from "lodash";
import ServiceDevice from "./ServiceDevice.vue";
import ServiceForm from "./ServiceForm.vue";
import ServiceDelivery from "./ServiceDelivery.vue";
import ServiceCustomerInformation from "./ServiceCustomerInformation.vue";
import ServiceProposalList from "./ServiceProposalList.vue";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    vSelect,
    flatPickr,
    ServiceDevice,
    ServiceForm,
    ServiceDelivery,
    ServiceCustomerInformation,
    ServiceProposalList,
    Logo,
    Cleave,
  },

  created() {
 
    if (this.sf.proposal.proposal_id == 0) 
    {
      this.sf.proposal.proposal_no =
        utils.moment().format("DMYY") +
        "" +
        utils.generateUniq({ size: 2, number: true });
    }
    if( this.sf.sform.customer_id > 0 )
    {
      this.sf.proposal.customer_id = this.sf.sform.customer_id;
    }
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["saveProposal", "downloadProposal"]),
    moment(v) {
      return utils.moment(v);
    },
    addNewItemProposal() {
        this.sf.proposal.items.push({pi_id:0,notes:'',quantity:1,price_unit:0,price_total:0});
    },
    removeItem(item, index) {
      this.sf.proposal.items.splice(index, 1);
    },
    getRowTotal(item) 
    {
   
      if(item == null || item == undefined) return;
      let row_total = 0;
      if (item.price_unit && item.price_unit.length > 0) {
        let d = parseFloat(item.price_unit.replace(",", ""));
        row_total = (d * item.quantity).toFixed(2);
      }
      item.price_total = row_total;
      this.calculateProposal();
    },
    calculateProposal() {
      let proposaltotal = 0;
      let grandtotal = 0;
      let discounttotal = 0;
      let kdvtotal = 0;
      let subtotal = 0;

      this.sf.proposal.items.map((item) => {
        let row_total = 0;
 
        if (item.price_unit && item.price_unit.length > 0) {
          let d = item.price_unit.replace(",", "");
          row_total = (d * item.quantity).toFixed(2);
        }

        item.price_total = row_total;
        proposaltotal = parseFloat(proposaltotal) + parseFloat(row_total);
      });

      if (
        this.sf.proposal.discount &&
        this.sf.proposal.discount.length > 0 &&
        this.sf.proposal.discount_type != ""
      ) {
        let discount = parseFloat(this.sf.proposal.discount.replace(",", ""));
        if (this.sf.proposal.discount_type == "price") {
          discounttotal = parseFloat(discount).toFixed(2);
        }
        if (this.sf.proposal.discount_type == "percent") {
          discounttotal =
            parseFloat(proposaltotal * discount) / (100).toFixed(2);
        }
      }

      subtotal = proposaltotal - discounttotal;

      if (this.sf.proposal.kdv > 0) {
        kdvtotal = parseFloat((subtotal * this.sf.proposal.kdv) / 100).toFixed(2);
      }

      grandtotal = parseFloat(subtotal) + parseFloat(kdvtotal);
      this.sf.proposal.proposal_total = proposaltotal;
      this.sf.proposal.discount_total = discounttotal;
      this.sf.proposal.sub_total = subtotal;
      this.sf.proposal.kdv_total = kdvtotal;
      this.sf.proposal.grand_total = grandtotal;
    },
    saveProposalEntity() {
      this.sf.proposal.sf_id = this.sf.sform.sf_id;
      this.saveProposal();
    },
    download() {
      let fileName = utils.generateUniq({ size: 2, number: true });
      if( this.sf.sform.customer_id > 0 )
      {
        fileName = `${this.sf.customer.title}-${this.sf.proposal.proposal_id}`;
      }
      this.downloadProposal(fileName+'.pdf');
    },
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
    }),
    getProposalItems() {
      let result = [];
      if(this.sf.proposal!=null  )
      {
        result = this.sf.proposal.items;
      }
      return result;
    },
    getBank() {
      let result = "";
      if (this.sf.proposal.bank_id > 0) {
        let bank = utils.getDefinition(this.sf.proposal.bank_id);
        if (bank) {
          result = bank;
        }
      }
      return result;
    },
    officeAddress() {
      return utils.getActiveUser().office.address;
    },
    customerInfo() {
      let result = { title: "", address: "" };
      if (this.sf.customer != null) {
        result.title = this.sf.customer.title;
        result.address = this.sf.customer.invoice_address;
      }
      return result;
    },
  },
};
</script>

<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="card-title">Ödemeler</div>
        <button v-on:click="newPayment()" class="btn btn-sm btn-primary">
          Yeni
        </button>
      </div>
      <div class="card-body p-0">
        <b-table
          show-empty
          emptyHtml="Listelenecek bir kayıt bulunmuyor."
          ref="selectabledevices"
          responsive
          striped
          hover
          selectable
          select-mode="range"
          :items="payment_items"
          :fields="datasource.table.fields"
          @row-selected="setPayment"
          v-model="datasource.table.selected"
          :tbody-tr-class="rowClass"
          class="mb-0 align-top"
        >
          <template #cell(p_id)="data"> #{{ data.value }} </template>
          <template #cell(status_item)="data">
            <status-badge :status="data.value" />
          </template>
          <template #cell(date_item)="data">
            <div v-if="data.value.planned_date != null">
              <span class="mwi1 d-inline-block">Planlanan:</span>
              <span class="d-inline-block">{{
                moment(data.value.planned_date).format("DD.MM.YYYY")
              }}</span>
            </div>
            <div class="" v-if="moment(data.value.complated_date).isValid()">
              <span class="mwi1 d-inline-block">Tamamlanma:</span>
              <span class="d-inline-block">{{
                moment(data.value.complated_date).format("DD.MM.YYYY HH:mm")
              }}</span>
            </div>
          </template>
          <template #cell(created_props)="data">
            {{ moment(data.value.created_at).format("DD.MM.YYYY HH:mm") }}
            <div>{{ data.value.created_by_name }}</div>
          </template>
        </b-table>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <b-row>
          <b-col>
            <b-row>
              <b-col lg="3">
                <div class="form-group">
                  <label class="text-uppercase font-medium"
                    >Ödeme Kategorisi</label
                  >
                  <v-select
                    v-model="sf.payment.category_id"
                    :options="global.payment_category"
                    :reduce="(option) => option.value"
                    :searchable="false"
                    :clearable="false"
                  />
                </div>
              </b-col>
              <b-col lg="9">
                <div class="row">
                  <div class="col-sm-6">
                <div class="form-group">
                  <label class="text-uppercase font-medium">İlgili Cihaz</label>
                  <v-select
                    v-model="sf.payment.device_id"
                    label="label"
                    :options="_devices"
                    :reduce="(option) => option.value"
                    :searchable="false"
                    :clearable="false"
                  />
                </div>
                  </div>
                  <div class="col-sm-6">
                <div class="form-group">
                  <label class="text-uppercase font-medium">İlgili Teslimat No</label>
                  <v-select
                    v-model="sf.payment.delivery_id"
                    label="label"
                    :options="_deliveries"
                    :reduce="(option) => option.value"
                    :searchable="false"
                    :clearable="false"
                  />
                </div>
                  </div>
                </div>
              </b-col>
              <b-col lg="12">
                <b-row>
                  <b-col lg="3">
                    <div class="form-group">
                      <label class="text-uppercase font-medium"
                        >Ödeme Tarihi</label
                      >
                      <flat-pickr
                        class="form-control"
                        v-model="sf.payment.planned_date"
                        :config="{
                          enableTime: true,
                          altFormat: 'd.m.Y',
                          altInput: true,
                          dateFormat: 'Y-m-d',
                          locale: 'tr',
                        }"
                      />
                    </div>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <div class="form-group">
                          <label class="text-uppercase font-medium"
                            >Ödenen Tarih</label
                          >
                          <flat-pickr
                            class="form-control"
                            v-model="sf.payment.complated_date"
                            :config="{
                              enableTime: true,
                              altFormat: 'd.m.Y H:i',
                              altInput: true,
                              dateFormat: 'Y-m-d H:i',
                              locale: 'tr',
                            }"
                          />
                        </div>
                      </b-col>
                      <b-col lg="6">
                        <div class="form-group">
                          <label class="text-uppercase font-medium"
                            >Ödeme Aracı</label
                          >
                          <v-select
                            v-model="sf.payment.method_id"
                            :options="global.payment_methods"
                            :reduce="(option) => option.value"
                            :searchable="false"
                            :clearable="false"
                          />
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <div class="form-group">
              <label class="text-uppercase font-medium">Notlar</label>
              <textarea
                v-model="sf.payment.notes"
                rows="3"
                class="form-control"
              ></textarea>
              <small class="text-muted"
                >* Müşteri tarafından görüntülenir.</small
              >
            </div>
            <div class="panelpayment">
              <div class="form-group">
                <label class="text-uppercase font-medium">Ödeme Linki</label>
                <input
                  v-model="sf.payment.payment_link"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-medium text-uppercase"
                    >Ödeme Miktarı</label
                  >
                  <b-input-group>
                    <b-form-input
                      type="number"
                      step="0.01"
                      autocomplate="off"
                      v-model="sf.payment.amount"
                    ></b-form-input>
                    <template #append>
                      <b-dropdown
                        :text="getDefinitionName(sf.payment.currency_id)"
                        variant="outline-secondary"
                      >
                        <b-dropdown-item
                          v-on:click="
                            sf.payment.currency_id = item.definition_id
                          "
                          v-for="item in global.currencies"
                          :key="item.definition_id"
                          >{{ item.label }}</b-dropdown-item
                        >
                      </b-dropdown>
                    </template>
                  </b-input-group>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="text-uppercase font-medium"
                        >Müşteri Görebilir</label
                      >
                      <v-select
                        v-model="sf.payment.show_customer"
                        :options="global.yesno"
                        :reduce="(option) => option.value"
                        :searchable="false"
                        :clearable="false"
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="text-uppercase font-medium">Personel Görebilir</label>
                      <v-select
                        v-model="sf.payment.show_user"
                        :options="global.yesno"
                        :reduce="(option) => option.value"
                        :searchable="false"
                        :clearable="false"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <b-row>
              <b-col lg="6">
                <div class="form-group">
                  <label class="text-uppercase font-medium">Durum</label>
                  <v-select
                    v-model="sf.payment.status"
                    :options="global.payment_status"
                    :reduce="(option) => option.value"
                    :searchable="false"
                    :clearable="false"
                  />
                </div>
              </b-col>
            </b-row>
            <div class="form-group">
              <button v-on:click="savePaymentItem()" class="btn btn-primary">
                Kaydet
              </button>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import StatusBadge from "@/views/common/StatusBadge.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import ServiceDevice from "./ServiceDevice.vue";
import utils from "@/service/utils";
export default {
  components: {
    vSelect,
    flatPickr,
    ServiceDevice,
    StatusBadge,
  },
  data() {
    return {
      datasource: {
        table: {
          selected: null,
          fields: [
            {
              key: "p_id",
              label: "No",
              sortable: false,
              selected: true,
            },
            {
              key: "category_name",
              label: "Kategori",
              sortable: false,
              selected: true,
            },
            {
              key: "date_item",
              label: "Tarih",
              sortable: false,
              selected: true,
            },
            {
              key: "status_item",
              label: "Durum",
              sortable: false,
              selected: true,
            },
            {
              key: "created_props",
              label: "Oluşturulma",
              sortable: false,
              selected: true,
            },
          ],
        },
      },
    };
  },
  created() {},
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
    }),
    payment_items() {
      let result = [];
      this.sf.payments.forEach((q) => {
        q.status_item = { status: q.status, status_text: q.status_text };
        q.date_item = {
          planned_date: q.planned_date,
          complated_date: q.complated_date,
        };
        result.push(q);
      });
      return result;
    },
    _devices() {
      let result = [];
      result.push({ label: "Seçin", value: "0" });
      this.sf.devices.forEach((q) => {
        result.push({ label: q.device_name, value: q.device_id });
      });
      return result;
    },
    _deliveries()
    {
      let result = [];
      result.push({ label: "Seçin", value: "0" });

    this.sf.delivery_list.forEach(q=>
    {
      let taskname = q.task!=null ? q.task.definition_name:'';
     result.push({ label:q.d_id+' '+taskname,value:q.d_id });
    });

     

      return result;
    }
  },
  methods: {
    ...mapActions(["savePayment", "sendSMSNotification"]),
    ...mapMutations(["newPayment"]),
    moment(v) {
      return utils.moment(v);
    },
    savePaymentItem() {
      if(this.sf.payment.show_customer == null)
      {
        utils.notificationToast('warning','Bilgi','Ödemenin müşteri tarafından görünürlüğünü kontrol edin.');
        return;
      }
      if(this.sf.payment.show_user == null)
      {
        utils.notificationToast('warning','Bilgi','Ödemenin kullanıcı tarafından görünürlüğünü kontrol edin.');
        return;
      }
      this.savePayment();
    },
    setPayment(item) {
      if (item == null || item.length == 0) return;
      item = Object.assign({}, item[0]);
      if (!utils.moment(item.complated_date).isValid()) {
        item.complated_date = null;
      }
      this.sf.payment = item;
    },
    getDefinitionName(id) {
      return utils.getDefinitionName(id);
    },
    rowClass(item, type) {
      if (!item || type !== "row") {
        return;
      }
    },
  },
};
</script>
 <style scoped>
.mwi1 {
  min-width: 8rem;
}
</style>
 
<template>
  <div>
    <card-customer-search
      entitytype="serviceform"
      :customer="sf.customer"
    ></card-customer-search>
    
    <card-customer-info
      v-if="sf.customer != null && sf.customer.customer_id > 0"
      :customer="sf.customer"
    ></card-customer-info>
    <div class="card">
      <div class="card-header">
        <div class="card-title">Servis Formu</div>
        <span class="card-title" v-if="sf.sform.sf_id > 0"
          >#{{ sf.sform.sf_id }}</span
        >
      </div>
      <div class="card-body border-top pt-2">
        <b-row>
          <b-col lg="6" sm="12">
            <div class="form-group">
              <label class="font-medium text-uppercase"
                >Servis Kayıt Tarihi</label
              >
              <flat-pickr
                v-model="sf.sform.sf_date"
                class="form-control"
                :config="{
                  enableTime: false,
                  altFormat: 'd.m.Y',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  locale: 'tr',
                }"
              />
            </div>
          </b-col>
          <b-col lg="6" sm="12">
            <div class="form-group">
              <label class="font-medium text-uppercase">Servis No</label>
              <input
                v-model="sf.sform.sf_no"
                type="text"
                class="form-control"
              />
            </div>
          </b-col>
        </b-row>
        <div class="form-group">
          <label class="font-medium text-uppercase">Özel Notlar</label>
          <textarea
            class="form-control"
            placeholder="Bu Form İçin Özel Notlardır"
            rows="4"
            v-model="sf.sform.sf_desc"
          ></textarea>
        </div>
        <div class="form-group mb-0">
          <b-form-checkbox v-model="sf.sform.config_send_notification"
            >SMS Bildirimleri Gönder</b-form-checkbox
          >
        </div>
        <div class="form-group">
          <b-form-checkbox v-model="sf.sform.config_customer_access_report"
            >Müşteri Bilgilendirme Paneline Erişebilir</b-form-checkbox
          >
        </div>
        <div class="form-group">
          <button class="btn btn-primary mr-1" @click="showmodal()">
            Müşteri Ara
          </button>
          <button @click="formSubmit()" type="button" class="btn btn-primary">
            Kaydet
          </button>
          <router-link
            v-if="crudmode == 'update' && false"
            class="btn btn-link"
            :to="{
              name: 'printreceipt',
              query: { uniq: sf.sform.sf_uniq },
            }"
          >
            Servis Fişi Yazdır
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest";
import utils from "@/service/utils";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import _ from "lodash";
import ServiceDevice from "./ServiceDevice.vue";
import CardCustomerSearch from "@/views/common/CardCustomerSearch.vue";
import CardCustomerInfo from "@/views/common/CardCustomerInfo.vue";
import CustomerServiceReceipt from "@/views/print/CustomerServiceReceipt.vue";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    VueAutosuggest,
    vSelect,
    flatPickr,
    ServiceDevice,
    CardCustomerSearch,
    CardCustomerInfo,
    CustomerServiceReceipt,
  },
  data() {
    return {
      show_modal_customer: false,
      customer_search: {
        datasuggest: [],
        query: "",
        suggestions: [],
        selected: null,
        inputProps: {
          id: "search_customer",
          class: "form-control",
          type: "search",
          placeholder: "gsm numarası, email, adsoyad, müşteri kodu ile ara",
        },
        sectionConfigs: {
          customers: {
            limit: 6,
            label: "",
            ulClass: "list-group list-none",
            liClass: "list-group-item",
            onSelected: (selected) => {
              this.sf.customer = selected.item;
              this.customer_search.query = selected.item.title;
              this.sf.sform.customer_id = selected.item.customer_id;
            },
          },
        },
      },
    };
  },
  created() {
    utils.setTitle("Yeni Servis Oluştur");
    this.clearSF();
  },
  methods: {
    ...mapActions(["createSF", "saveSF", "getServiceDetails"]),
    ...mapMutations(["clearSF"]),
    formSubmit() {
      if (this.crudmode == "create") {
        if (this.sf.customer == null) {
          utils.notificationToast("warning", "Bilgi", "Müşteri seçin.");
          return;
        }
        this.createSF().then((q) => {
          if (q.success == true) {
            utils.notificationToast("success", "Bilgi", q.message);
            this.$router.replace({
              name: "service",
              query: { uniq: q.data.sf.sf_uniq },
            });
          } else {
            utils.notificationToast("warning", "Uyarı", q.message);
          }
        });
      } else {
        this.saveSF().then((q) => {
          if (q.success == true) {
            utils.notificationToast("success", "Bilgi", q.message);
          } else {
            utils.notificationToast("warning", "Uyarı", q.message);
          }
        });
      }
    },
    searchCustomer() {
      let keyword = this.customer_search.query;
      this.$store.dispatch("searchCustomer", { keyword, type: "customer" });
    },
    clearSelectedCustomer() {
      this.customer_search.query = "";
      this.customer_search.suggestions = [];
      this.sf.sform.customer_id = 0;
      this.sf.customer = null;
    },
    showmodal() {
      this.$root.$emit("bv::toggle::modal", "modal_customer");
    },
    renderSuggestion(suggestion) {
      const data = suggestion.item;
      return (
        <b-list-group>
          <b-list-group-item class="p-1">
            <div class="d-flex align-items-center">
              <div class="d-flex">
                <div>
                  <b-avatar src={data.profile_image} class="mr-50"></b-avatar>
                </div>
                <div>
                  <div>
                    <span>{data.title}</span>
                  </div>
                  <div>
                    <span>{data.gsm_primary}</span>
                    <span class="ml-1">{data.email_primary}</span>
                  </div>
                </div>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      );
    },
    printServiceReceipt() {
      // Pass the element id here
    },
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
    }),
    crudmode() {
      return ["", "0"].indexOf(this.sf.sform.sf_id) > -1 ? "create" : "update";
    },
  },
  watch: {
    "sf.customer_search_list": function (newval, oldval) {
      this.customer_search.suggestions = [];
      this.customer_search.suggestions.push({
        name: "customers",
        data: newval,
      });
    },
    "$route.query": function (n, o) {
      if (n.uniq == null) {
        this.clearSF();
      }

      if (n.uniq && ["", "0"].indexOf(this.sf.sform.sf_id) > -1) {
        this.sf.sform.sf_uniq = n.uniq;
        setTimeout(() => {
          this.getServiceDetails();
        });
      }
    },
  },
};
</script>
 